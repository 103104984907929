import { Controller } from "stimulus"
import Tagify from "@yaireo/tagify/dist/tagify.esm.js"

export default class extends Controller {
  static values = { tags: Boolean }

  connect() {
    let options = {}

    new Tagify(this.element, options)
  }
}
