import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "ShippingAddressContainer",
    "ShippingNameInput", "ShippingAddressInput", "ShippingCityInput", "ShippingStateInput", "ShippingZipcodeInput",
    "BillingNameInput", "BillingAddressInput", "BillingCityInput", "BillingStateInput", "BillingZipcodeInput",
    "ShippingSameAsBillingInput",
    "SummaryOrderItemPrice", "SummarySubtotal", "SummaryShipping", "SummaryPalletFee", "SummaryCreditCardLine", "SummaryCreditCardAmount", "SummaryTotalAmount",
    "MailCheckInput", "AchInput", "CreditCardInput", "JdfInput",
    "CreditCardLabel", "JdfLabel",
    "NoCreditCardMessage", "MailCheckInstructionText", "AchInstructionText", "CreditCardInstructionText", "AdminCantFwdCreditCardFee", "JdfInstructionText", "OrdersFinalNotice", "SubmitButton",
    "NoUntreatedAlert", "NoTreatedAlert", "OrganicLowPurityAlert", "NonGmoLowPurityAlert",
    "PreferredShipDateInput", "AsapRadioButton", "SpecifyRadioButton"
  ]

  static values = {
    canUseCardFee: Boolean,
    adminUser: Boolean
  }

  connect() {
    this.statesNoCardFee = ['CA', 'CO', 'CT', 'FL', 'KS', 'ME', 'MA', 'NY', 'OK', 'TX']
    this.jdfAddedCostPercent = 1.0625
    this.currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })

    this.tryHideCardPaymentOption()
    this.initPaymentMethodDisplay()
    this.togglePreferredShipDateInput();
  }

  togglePreferredShipDateInput() {
    if (this.SpecifyRadioButtonTarget.checked) {
      this.PreferredShipDateInputTarget.style.display = "block";
    } else {
      this.PreferredShipDateInputTarget.style.display = "none";
    }
  }

  handlePreferredShipDateChange(event) {
    this.togglePreferredShipDateInput();
  }

  onUntreatedChoiceChange(e) {
    if (this.hasNoUntreatedAlertTarget) {
      if (e.target.value == "true") {
        this.NoUntreatedAlertTarget.classList.remove("hidden")
      } else {
        this.NoUntreatedAlertTarget.classList.add("hidden")
      }
    }
    if (this.hasNoTreatedAlertTarget) {
      if (e.target.value == "false") {
        this.NoTreatedAlertTarget.classList.remove("hidden")
      } else {
        this.NoTreatedAlertTarget.classList.add("hidden")
      }
    }
  }

  onCertifiedOrganicMarketChange(e) {
    if (this.hasOrganicLowPurityAlertTarget) {
      if (e.target.value == "true") {
        this.OrganicLowPurityAlertTarget.classList.remove("hidden")
      } else {
        this.OrganicLowPurityAlertTarget.classList.add("hidden")
      }
    }
  }

  onNonGmoMarketChange(e) {
    if (this.hasNonGmoLowPurityAlertTarget) {
      if (e.target.value == "true") {
        this.NonGmoLowPurityAlertTarget.classList.remove("hidden")
      } else {
        this.NonGmoLowPurityAlertTarget.classList.add("hidden")
      }
    }
  }

  onShippingSameAsBillingChange(e) {
    if (e.target.checked) {
      this.ShippingAddressContainerTarget.classList.remove("visible", "opacity-100", "h-auto")
      this.ShippingAddressContainerTarget.classList.add("invisible", "opacity-0", "h-0")
      this.copyBillingToShipping()
    } else {
      this.ShippingAddressContainerTarget.classList.remove("invisible", "opacity-0", "h-0")
      this.ShippingAddressContainerTarget.classList.add("visible", "opacity-100", "h-auto")
      this.clearShippingFields()
    }
  }

  onBillingFieldChange() {
    if (this.ShippingSameAsBillingInputTarget.checked) {
      this.copyBillingToShipping()
    }
  }

  onBillingStateChange(e) {
    if (this.statesNoCardFee.indexOf(e.target.value) >= 0) {
      this.hideCreditCardFee()
      this.hideCreditCardPaymentOption()
      this.canUseCardFeeValue = false
    } else {
      this.canUseCardFeeValue = true
      this.showCreditCardPaymentOption()
    }

    this.onBillingFieldChange()
  }

  onPaymentMethodChange(e) {
    if (e.currentTarget.dataset.billingTarget == "MailCheckInput") {
      this.MailCheckInstructionTextTarget.classList.remove("hidden")
      this.AchInstructionTextTarget.classList.add("hidden")
      this.CreditCardInstructionTextTarget.classList.add("hidden")
      this.JdfInstructionTextTarget.classList.add("hidden")
      this.hideCreditCardFee()
      this.showOrdersFinalNotice()
    }
    if (e.currentTarget.dataset.billingTarget == "AchInput") {
      this.MailCheckInstructionTextTarget.classList.add("hidden")
      this.AchInstructionTextTarget.classList.remove("hidden")
      this.CreditCardInstructionTextTarget.classList.add("hidden")
      this.JdfInstructionTextTarget.classList.add("hidden")
      this.hideCreditCardFee()
      this.showOrdersFinalNotice()
    }
    if (e.currentTarget.dataset.billingTarget == "CreditCardInput") {
      this.MailCheckInstructionTextTarget.classList.add("hidden")
      this.AchInstructionTextTarget.classList.add("hidden")
      this.CreditCardInstructionTextTarget.classList.remove("hidden")
      this.JdfInstructionTextTarget.classList.add("hidden")
      this.showCreditCardFee()
      this.hideOrdersFinalNotice()
    }
    if (e.currentTarget.dataset.billingTarget == "JdfInput") {
      this.MailCheckInstructionTextTarget.classList.add("hidden")
      this.AchInstructionTextTarget.classList.add("hidden")
      this.CreditCardInstructionTextTarget.classList.add("hidden")
      this.JdfInstructionTextTarget.classList.remove("hidden")
      this.hideCreditCardFee()
      this.hideOrdersFinalNotice()
    }
  }

  tryHideCardPaymentOption() {
    if (this.canUseCardFeeValue == false && this.statesNoCardFee.indexOf(this.ShippingStateInputTarget.value >= 0)) {
      this.hideCreditCardPaymentOption()
    }
  }

  initPaymentMethodDisplay() {
    if (this.MailCheckInputTarget.checked) {
      this.MailCheckInstructionTextTarget.classList.remove("hidden")
      this.AchInstructionTextTarget.classList.add("hidden")
      this.CreditCardInstructionTextTarget.classList.add("hidden")
      this.hideCreditCardFee()
    }
    if (this.AchInputTarget.checked) {
      this.MailCheckInstructionTextTarget.classList.add("hidden")
      this.AchInstructionTextTarget.classList.remove("hidden")
      this.CreditCardInstructionTextTarget.classList.add("hidden")
      this.hideCreditCardFee()
    }
    if (this.CreditCardInputTarget.checked) {
      this.MailCheckInstructionTextTarget.classList.add("hidden")
      this.AchInstructionTextTarget.classList.add("hidden")
      this.CreditCardInstructionTextTarget.classList.remove("hidden")
      this.showCreditCardFee()
    }
  }

  copyBillingToShipping() {
    this.ShippingNameInputTarget.value = this.BillingNameInputTarget.value
    this.ShippingAddressInputTarget.value = this.BillingAddressInputTarget.value
    this.ShippingCityInputTarget.value = this.BillingCityInputTarget.value
    this.ShippingStateInputTarget.value = this.BillingStateInputTarget.value
    this.ShippingZipcodeInputTarget.value = this.BillingZipcodeInputTarget.value
  }

  clearShippingFields() {
    this.ShippingNameInputTarget.value = ""
    this.ShippingAddressInputTarget.value = ""
    this.ShippingCityInputTarget.value = ""
    this.ShippingStateInputTarget.value = ""
    this.ShippingZipcodeInputTarget.value = ""
  }

  hideCreditCardPaymentOption() {
    if (!this.adminUserValue) {
      this.CreditCardInputTarget.disabled = true
      this.CreditCardLabelTarget.classList.add("opacity-50", "cursor-not-allowed")
      if (this.CreditCardInputTarget.checked) {
        this.CreditCardInputTarget.checked = false
        this.AchInputTarget.checked = true
      }
      this.NoCreditCardMessageTarget.classList.remove("hidden")
      this.AchInstructionTextTarget.classList.remove("hidden")
      this.canUseCardFeeValue = false
    } else {
      if (this.hasAdminCantFwdCreditCardFeeTarget) {
        this.AdminCantFwdCreditCardFeeTarget.classList.remove("hidden")
      }
    }
  }

  showCreditCardPaymentOption() {
    this.CreditCardInputTarget.disabled = false
    this.CreditCardLabelTarget.classList.remove("opacity-50", "cursor-not-allowed")
    this.NoCreditCardMessageTarget.classList.add("hidden")
    this.canUseCardFeeValue = true
  }

  showOrdersFinalNotice() {
    this.OrdersFinalNoticeTarget.classList.remove("hidden")
  }

  hideOrdersFinalNotice() {
    this.OrdersFinalNoticeTarget.classList.add("hidden")
  }

  hideCreditCardFee() {
    if ((this.adminUserValue || this.canUseCardFeeValue == true) && Array.from(this.SummaryCreditCardLineTarget.classList).indexOf("hidden") < 0) {
      this.SummaryCreditCardLineTarget.classList.add("hidden")
      this.CreditCardInstructionTextTarget.classList.add("hidden")
      var newTotal = this.totalAmount() - this.cardFeeAmount()
      this.SummaryTotalAmountTarget.innerHTML = `$${Number(+(Math.round(newTotal + "e+2") + "e-2")).toFixed(2)}`
      this.SubmitButtonTarget.value = "Submit Order"
    }
  }

  showCreditCardFee() {
    const shippingState = this.ShippingStateInputTarget.value
    const billingState = this.BillingStateInputTarget.value
    const billingStateCantFwdFee = billingState !== "" && this.statesNoCardFee.indexOf(billingState) >= 0
    const shippingStateCantFwdFee = shippingState !== "" && this.statesNoCardFee.indexOf(shippingState) >= 0

    if (this.adminUserValue || (billingState && !billingStateCantFwdFee) || (shippingState && !shippingStateCantFwdFee)) {
      if (this.adminUserValue || (this.canUseCardFeeValue == true && Array.from(this.SummaryCreditCardLineTarget.classList).includes("hidden"))) {
        this.SummaryCreditCardLineTarget.classList.remove("hidden")
        this.CreditCardInstructionTextTarget.classList.remove("hidden")
        var newTotal = this.totalAmount() + this.cardFeeAmount()
        this.SummaryTotalAmountTarget.innerHTML = `$${Number(+(Math.round(newTotal + "e+2") + "e-2")).toFixed(2)}`
        this.SubmitButtonTarget.value = "Finish & Pay"
      }
    }
  }

  totalAmount() {
    var totalAmount = this.SummaryTotalAmountTarget.textContent
    totalAmount = totalAmount.replace("$", "").replace(",", "")
    return parseFloat(totalAmount)
  }

  cardFeeAmount() {
    var feeAmount = this.SummaryCreditCardAmountTarget.textContent
    feeAmount = feeAmount.replace("$", "").replace(",", "")
    return parseFloat(feeAmount)
  }

}