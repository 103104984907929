import './home.sass'
import '../product_card/product_card.sass'

$(document).ready(() => {
  var emailSubmitButton = $('#mc-embedded-subscribe')[0]
  var emailSignupForm = $('#mc-embedded-subscribe-form')[0]
  var emailSignupSuccess = $('#email-signup-success')[0]
  var emailSignupError = $('#email-signup-error')[0]

  function showEmailSignupError(error) {
    $(emailSignupError).html(error)
    $(emailSignupError)[0].classList.add('-is-visible')
  }

  if (emailSubmitButton) {
    $(emailSubmitButton).bind(' click', (event) => {
      event.preventDefault()
      $(emailSignupError)[0].classList.remove("-is-visible")

      $.ajax({
        method: $(emailSignupForm).attr('method'),
        url: $(emailSignupForm).attr('action'),
        dataType: 'json',
        data: $(emailSignupForm).serialize(),
        error: (err) => {
          console.log('err: ', err)
          if (err.responseJSON) {
            showEmailSignupError(err.responseJSON.error)
          } else {
            showEmailSignupError("Error submitting form. Please contact us for assistance.")
          }
        },
        success: (data) => {
          $(emailSignupForm)[0].classList.add('-is-hidden')
          $(emailSignupError)[0].classList.add('-is-hidden')
          $(emailSignupSuccess)[0].classList.add('-is-visible')
          if (window.current_admin_user == null) {
            if (window.gtag) {
              gtag('event', 'EmailNewsletterSignup', { event_category: 'Lead', currency: 'USD', value: 20.00 })
            }
            if (window.fbq) {
              fbq('track', 'ViewContent', { currency: 'USD', value: 20.00 })
            }
          }
        }
      })
    })
  }
})
