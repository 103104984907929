$(document).ready(() => {
  // auto-update quantity on input
  var quantityInputPrevFocusValue = null
  var quantityInputFocusedInterval = null

  var updateFunction = function(e) {
    e.preventDefault()

    var orderItemId = $(this).data("id")
    var updateForm = $(`#edit_api_models_order_item_${orderItemId}`)[0]

    $.ajax({
      method: "PUT",
      dataType: "json",
      url: updateForm.action,
      data: $(updateForm).serialize()
    }).done((response) => {
      $($("#total-amount")[0]).html(response.orderSubtotal)
      var price = $($(`#cart-total-price-${orderItemId}`)[0])
      price.html(response.orderItemTotal)
      $("#cart-row-price-"+response.orderItemId).html("$"+response.orderItemPrice.toFixed(2))
      $($("#error-" + orderItemId)[0]).removeClass('-visible')
      $(".checkout-button").prop("disabled", false)

      if (response.discountAmount) {
        // update discount amount
        $($("#promo-code-pre-discount-amount")[0]).html(response.orderSubtotal)
        // update subtotal to reflect
        $("#promo-code-discount-amount").html("$"+response.discountAmount.toFixed(2))
        $($("#total-amount")[0]).html("$"+(Number(response.orderSubtotal.replace(/[^0-9\.-]+/g,"")) - response.discountAmount.toFixed(2)).toFixed(2))
      }
    }).fail((response) => {
      if (!response.responseJSON) {
        Sentry.captureMessage('Error updating item in cart: ' + response)
      }
      var quantityError = response.responseJSON.errors.quantity
      if (quantityError && quantityError[0]) {
        quantityError = quantityError[0].replace(',is not a number', '')
        $($("#error-" + orderItemId)[0]).html('Quantity ' + quantityError)
        $($("#error-" + orderItemId)[0]).addClass('-visible')
        $(".checkout-button").prop("disabled", true)
      }
    })
  }

  // auto-update quantity on change
  $(".cart-quantity").on('change', (e) => {
    if ($(this).val() <= 1) {
      $(this).val(1)
    }

    updateFunction(e)
  })

  // prevent clicking "enter"/submit on cart quantity input fields
  $(".edit_order_item").on('submit', (e) => {
    e.preventDefault()
  })

  // periodically update quantity if still having focus
  // attempt to address bug of mobile input having focus while clicking continue
  $(".cart-quantity").on('focus', (e) => {
    quantityInputFocused = true
    quantityInputPrevFocusValue = $(e.target).val()

    quantityInputFocusedInterval = setInterval(() => checkUpdateOrderItemQuantity(e), 500)
  })

  function checkUpdateOrderItemQuantity(e) {
    var orderItemId = $(e.target).data('id')

    // if input still has focus
    if ($(document.activeElement).data('id') == orderItemId) {
      // and value is different
      if ($(e.target).val() !== quantityInputPrevFocusValue) {
        quantityInputPrevFocusValue = $(e.target).val()
        if ($(e.target).val() !== "") {
          updateOrderItemQuantity(e)
        }
      }
    } else {
      clearInterval(quantityInputFocusedInterval)
    }
  }

  // remove order item
  var deleteFunction = function(e) {
    e.preventDefault()

    var orderItemId = $(this).data("id")
    var title = $(this).data("title")

    if (confirm(`Are you sure you want to delete '${title}' from your cart?`)) {
      $("#cart-table").hide()
      $("#cart-spinner").show()
      $.ajax({
        method: "DELETE",
        dataType: "json",
        url: `/order_items/${orderItemId}`
      }).done((response) => {
        $("#cart-table").show()
        $("#cart-spinner").hide()
        var itemCount = response.itemCount
        $($(".cart-count")[0]).html(itemCount)
        $($("#total-amount")[0]).html(response.orderSubtotal)
        $($(`#cart-row-${orderItemId}`)[0]).remove()

        if (itemCount === 0) {
          $(".checkout-button").hide()
          $(".hybrid85-show-on-empty").addClass("-visible")
          $(".hybrid85-hide-on-empty").addClass("-hidden")
        }

        if (response.allSameOrigin == true) {
          $(".checkout-button").attr("data-all-same-origin", true)
        }

        if (response.discountAmount) {
          // update discount amount
          $($("#promo-code-pre-discount-amount")[0]).html(response.orderSubtotal)
          // update subtotal to reflect
          $("#promo-code-discount-amount").html("$"+response.discountAmount.toFixed(2))
          $($("#total-amount")[0]).html("$"+(response.orderSubtotal.slice(1) - response.discountAmount.toFixed(2)).toFixed(2))
        }
      }).fail((error) => {
        alert("There was an error removing this item from the cart.")
        $("#cart-spinner").html("Please refresh the page.")
        Sentry.captureMessage('Error deleting item from cart: ' + error)
      })
    } else {
      // do nothing
    }
  }

  $(".cart-remove").on('touchstart click', deleteFunction)

  // * * * * * CHECKOUT MODAL STUFF

  var contactMeModal = $('#contact-me-modal')[0]
  var modalCloseButton = $('#contact-me-close-button')[0]
  var checkoutButton = $('.checkout-button')[0]

  if (checkoutButton) {
    $(checkoutButton).on('touchstart click', (e) => {
      if ($('.checkout-button').attr('data-all-same-origin') === "false") {
        e.preventDefault()

        if (contactMeModal) {
          $(contactMeModal).removeClass('hidden')
        }
      }
    })
  }

  // close modal
  var closeContactMeModal = (event) => {
    if (contactMeModal) {
      $(contactMeModal).addClass('hidden')
    }
  }

  var showSuccessModal = () => {
    $('#success-modal').removeClass('hidden')

    $('#success-close-button').on('touchstart click', () => {
      $('#success-modal').addClass('hidden')
      if (location.pathname.indexOf("/cart") >= 0) {
        location = "/"
      }
    })
  }

  if (modalCloseButton) {
    $(modalCloseButton).on('touchstart click', closeContactMeModal)
  }

  // order form submit
  var orderSubmitButton = $('#order-submit-button')[0]
  var orderId = $(orderSubmitButton).data('id')
  var orderForm = $('#edit_api_models_order_' + orderId)[0]

  if (orderSubmitButton) {
    orderSubmitButton.addEventListener('click', (event) => {
      event.preventDefault()

      $('.contact-me-error-container').hide()

      $.ajax({
        method: "PUT",
        dataType: 'json',
        url: orderForm.action,
        data: $(orderForm).serialize()
      }).done((response) => {
        $(orderForm).trigger("reset")

        if (window.current_admin_user == null) {
          if (window.gtag) {
            gtag('event', 'RequestedShipping', { event_category: 'Lead', currency: 'USD', value: 10.00 })
          }
          if (window.fbq) {
            fbq('track', 'InitiateCheckout', { currency: 'USD', value: 10.00 })
          }
        }

        if (response.redirect_url) {
          window.location = response.redirect_url
        }
      }).fail((error) => {
        var errorJson = error.responseJSON
        var errorHtml = []

        if (errorJson) {
          $.each(errorJson.errors && errorJson.errors.full_messages, (index, error) => {
            errorHtml = errorHtml.concat('<li>' + error + '</li>')
          })
        } else {
          errorHtml = errorHtml.concat('<li>Oops! Something went wrong on our end. Please contact us for support</li>')
        }

        $('.contact-me-errors').html(errorHtml.join(''))
        $('.contact-me-error-container').show()
      })
    })
  }

  // PROMO CODE
  $("#promo-code-placeholder").on('click', () => {
    $("#promo-code-placeholder").hide()
    $("#promo-code-form").removeClass("hidden")
    $(".promo-code-error").hide()
  })
})
