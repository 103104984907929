import "./listing_form.sass"
import $ from 'jquery'
import "jquery-ui/ui/widgets/datepicker"
import Tagify from "@yaireo/tagify/dist/tagify.esm.js"

var todayDateAsMMDDYYYY = function() {
  var today = new Date(),
      month = '' + (today.getMonth() + 1),
      day = '' + today.getDate(),
      year = today.getFullYear();

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [month, day, year].join('/')
}

$(document).ready(() => {
  var input = document.getElementById("api_models_listing_cost_price")
  if (input) {
    input.addEventListener("mousewheel", function(event){ this.blur() })
  }

  $("#api_models_listing_availability_date").datepicker({
    dateFormat:'mm/dd/yy'
  })

  if ($("#api_models_listing_species_tagify").length) {
    const speciesElem = $("#api_models_listing_species_tagify")[0]
    const tagify = new Tagify(speciesElem, {
      autoComplete: { enabled: true },
      dropdown: { enabled: 0 },
      maxTags: 1,
      whitelist: eval(speciesElem.value)
    })

    tagify.on('change', (e) => {
      const valueObject = e.detail.tagify.getCleanValue()
      if (valueObject[0] && valueObject[0].value) {
        $("#api_models_listing_species")[0].value = valueObject[0].value
      } else {
        $("#api_models_listing_species")[0].value = ""
      }
    })
  }

  $("#variety_vns").change(() => {
    if ($("#variety_vns").is(':checked')) {
      $("#api_models_listing_variety").prop('disabled', true)
      $("#api_models_listing_variety").val('VNS')
    } else {
      $("#api_models_listing_variety").prop('disabled', false)
      $("#api_models_listing_variety").val('')
    }
  })

  $("#listing-test-image").on('touchstart click', (e) => {
    if ($("#listing-test-image").hasClass("-disabled")) {
      e.preventDefault()
    }
  })

  if ($("#api_models_listing_treated_false").is(':checked')) {
    $("#listing-form-treated-how").hide()
    $("#listing-form-treated-yes-info").hide()
  }

  $("#api_models_listing_treated_false").change(() => {
    if ($("#api_models_listing_treated_false").is(':checked')) {
      $("#listing-form-treated-how").hide()
      $("#listing-form-treated-yes-info").hide()
    }
  })

  $("#api_models_listing_treated_true").change(() => {
    if ($("#api_models_listing_treated_true").is(':checked')) {
      $("#listing-form-treated-how").show()
      $("#listing-form-treated-yes-info").show()
    }
  })


  $("#api_models_listing_tested_true").change(() => {
    if ($("#api_models_listing_tested_true").is(':checked')) {
      $("#listing-test-image").removeClass('-disabled')
      $("#listing-form-seed-test-info").hide()
    }
  })

  $("#api_models_listing_tested_false").change(() => {
    if ($("#api_models_listing_tested_false").is(':checked')) {
      $("#listing-test-image").addClass('-disabled')
      $("#listing-form-seed-test-info").show()
    }
  })

  $("#api_models_listing_pvp_true").change(() => {
    if ($("#api_models_listing_pvp_true").is(':checked')) {
      $("#listing-form-pvp-agreement").hide()
      $("#listing-form-pvp-true-info").show()
      $("#listing-form-pvp-dontknow-info").hide()
    }
  })

  $("#api_models_listing_pvp_false").change(() => {
    if ($("#api_models_listing_pvp_false").is(':checked')) {
      $("#listing-form-pvp-agreement").show()
      $("#listing-form-pvp-true-info").hide()
      $("#listing-form-pvp-dontknow-info").hide()
    }
  })

  $("#api_models_listing_pvp_dontknow").change(() => {
    if ($("#api_models_listing_pvp_dontknow").is(':checked')) {
      $("#listing-form-pvp-agreement").hide()
      $("#listing-form-pvp-true-info").hide()
      $("#listing-form-pvp-dontknow-info").show()
    }
  })

  $('#api_models_listing_original_seed_test_image_input').change(() => {
    var fileName = ''
    fileName = $('#api_models_listing_original_seed_test_image_input').val()
    var fileNameParts = fileName.split("\\")
    fileName = fileNameParts[fileNameParts.length - 1]
    $('#listing-test-image-filename').html(fileName)

    if (fileName) {
      $('#listing-test-image').html("Seed Analysis Added")
      $('#api_models_listing_original_seed_test_image').val($('#api_models_listing_original_seed_test_image_input').val())
    }
  })

  $("#api_models_availability_date_now").change(() => {
    if ($("#api_models_availability_date_now").is(':checked')) {
      $("#api_models_listing_availability_date").prop('disabled', true)
      $("#api_models_listing_availability_date").val(todayDateAsMMDDYYYY())
    } else {
      $("#api_models_listing_availability_date").prop('disabled', false)
      $("#api_models_listing_availability_date").val('')
    }
  })

  // price to our price calculation
  $("#api_models_listing_cost_price").keyup((e) => {
    var isNumber = /^[0-9]$/i.test(e.key)

    // keyCode 8 = Backspace, keyCode 46 = Delete
    if (isNumber || e.keyCode == 8 || e.keyCode == 46) {
      var costPrice = $("#api_models_listing_cost_price").val()
      var container = $("#listing-retail-price-container")
      var species = $("#api_models_listing_species").val()

      if (costPrice > 0) {
        $.ajax({
          url: "/markup_price?retail_supplier=false&species="+species+"&price_per="+costPrice,
          method: "GET",
          dataType: 'json',
          crossDomain: true,
          headers: {
            "accept": "application/json",
            "Access-Control-Allow-Origin": "*"
          }
        }).done((response) => {
          var retailPrice = response.price.toFixed(2)

          $(container).addClass("-is-visible")
          $("#retail-price-value").html(retailPrice)
        }).fail((error) => {
          console.log('error: ', error)
        })
      } else {
        $(container).removeClass("-is-visible")
      }
    }
  })

  $("#listing-submit").on('touchstart click', (e) => {
    $(".listing-uploading-notification").addClass("-visible")

    $(".listing-form input[type='text']").each((index, input) => {
      if ($(input).prop('disabled')) {
        $(input).prop('disabled', false)
      }
    })

    $("#listing-submit").prop('disabled', true)
    $(".listing-form").submit()
  })

  // on error page load, set checkboxes based on values
  if ($("#listing_variety").val() === "VNS") {
    $("#variety_vns").prop('checked', true)
    $("#listing_variety").prop('disabled', true)
  }

  if ($("#api_models_listing_tested_true").prop("checked")) {
    $("#listing-test-image").removeClass('-disabled')
    $("#listing-form-seed-test-info").hide()
  }

  if ($("#api_models_listing_tested_false").prop("checked")) {
    $("#listing-form-seed-test-info").show()
  }

  if ($("#api_models_listing_availability_date").val() === todayDateAsMMDDYYYY()) {
    $("#availability_date_now").prop('checked', true)
    $("#listing_availability_date").prop('disabled', true)
  }

  if ($("#api_models_listing_pvp_true").prop("checked")) {
    $("#listing-form-pvp-true-info").show()
  }

  if ($("#api_models_listing_pvp_false").prop("checked")) {
    $("#listing-form-pvp-agreement").show()
  }

  if ($("#api_models_listing_pvp_dontknow").prop("checked")) {
    $("#listing-form-pvp-dontknow-info").show()
  }

  if ($("#api_models_listing_cost_price").val() > 0) {
    var costPrice = $("#api_models_listing_cost_price").val()

    if (costPrice > 0) {
      var retailPrice = (costPrice * 1.1765).toFixed(2)
      $("#retail-price-value").html(retailPrice)
    }

    $("#listing-retail-price-container").addClass("-is-visible")
  }

  // if existing file set, enable button/form
  if ($.trim($("#listing-test-image-filename").text()).length > 0) {
    $("#listing-test-image").removeClass('-disabled')
    $("#listing-form-seed-test-info").hide()
  }
})
