import { Controller } from 'stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    'ContentContainer',
    'PendingResultsContainer',
    'ShippingResultsContainer',
    'LookupFailedContainer',
    'SummarySubtotal',
    'SummaryShipping',
    'SummaryPallet',
    'SummaryTotal',
    'RatePerUnitBeforeDiscount',
    'RatePerUnit',
    'RateAmount'
  ]

  connect() {
    this.shippingRequestAttemptCount = 0

    if (this.hasShippingResultsContainerTarget || this.hasLookupFailedContainerTarget) {
      this.trackAnalyticsEvent()
    }
    if (this.hasPendingResultsContainerTarget) {
      this.startPolling()
    }
  }

  onShippingSelectionChange(e) {
    const radioRateElem = this.RateAmountTargets.find((target) => target.dataset.index == e.target.dataset.index)
    var radioRate = radioRateElem.innerHTML
    radioRate = radioRate.replace("$", "").replace(",", "")
    radioRate = parseFloat(radioRate)
    if (radioRateElem.dataset.discountPercent && Number(radioRateElem.dataset.discountPercent) > 0) {
      radioRate = radioRate * (1 - Number(radioRateElem.dataset.discountPercent) / 100)
    }
    this.updateOrderSummaryForShipping(radioRate)
    this.updateRatePerLbForShipping(radioRate)
  }

  trackAnalyticsEvent() {
    if (window.current_admin_user == null) {
      if (window.gtag) {
        gtag('event', 'RequestedShipping', { event_category: 'Lead', currency: 'USD', value: 10.00 })
      }
      if (window.fbq) {
        fbq('track', 'InitiateCheckout', { currency: 'USD', value: 10.00 })
      }
    }
  }

  startPolling() {
    if (this.PendingResultsContainerTarget.dataset.status == 'pending') {
      setTimeout(() => this.pollForStatusUpdate(this.shippingRequestAttemptCount), 5000)
    }
  }

  async pollForStatusUpdate(count) {
    const attemptCount = count + 1

    const request = new FetchRequest("GET", `/shipping_requests/${this.element.dataset.id}?from_js=true`, { responseKind: 'json' })
    const response = await request.perform()

    if (response.ok) {
      const responseJson = await response.json
      if (responseJson.status == 'pending') {
        if (attemptCount > 24) {
          this.handleShippingLookupError()
        } else {
          setTimeout(() => this.pollForStatusUpdate(attemptCount), 5000)
        }
      } else if (responseJson.status == 'done') {
        this.trackAnalyticsEvent()

        window.location.reload()
      } else if (responseJson.status == 'failed') {
        this.handleShippingLookupError()
      }
    } else {
      const error = await response.json
      this.handleShippingLookupError()

      if (window.Sentry) {
        Sentry.captureMessage('Error on shipping lookup: ' + JSON.stringify(error))
      }
    }
  }

  async handleShippingLookupError() {
    const request = new FetchRequest("GET", `/shipping_requests/${this.element.dataset.id}/error_partial`, { responseKind: 'html' })
    const response = await request.perform()

    if (response.ok) {
      const responseBody = await response.text

      this.ContentContainerTarget.innerHTML = responseBody
    } else {
      const error = await response.text

      alert(error)
    }
  }

  updateOrderSummaryForShipping(rate) {
    var subTotal = this.SummarySubtotalTarget.innerHTML
    subTotal = subTotal.replace("$", "").replace(",", "")
    subTotal = parseFloat(subTotal)
    var pallet = this.SummaryPalletTarget.innerHTML
    pallet = pallet.replace("$", ""). replace(",", "")
    pallet = parseFloat(pallet)
    const total = Math.round((subTotal + pallet + rate) * 100) / 100
    this.SummaryShippingTarget.innerHTML = `$${rate.toFixed(2)}`
    this.SummaryTotalTarget.innerHTML = `$${total.toFixed(2).toLocaleString()}`
  }

  updateRatePerLbForShipping(rate) {
    const weight = parseFloat(this.RatePerUnitTarget.dataset.weight)
    const ratePerUnit = rate.toFixed(2) / weight.toFixed(2)
    this.RatePerUnitTarget.innerHTML = `$${ratePerUnit.toFixed(2).toLocaleString()}`

    if (this.hasRatePerUnitBeforeDiscountTarget) {
      const discountPercent = Number(this.RatePerUnitBeforeDiscountTarget.dataset.discountPercent)
      const ratePerUnitBeforeDiscount = (rate / (1 - discountPercent / 100)).toFixed(2) / numUnits.toFixed(2) + 85.0
      this.RatePerUnitBeforeDiscountTarget.innerHTML = `$${ratePerUnitBeforeDiscount.toFixed(2).toLocaleString()}`
    }
  }

}