import { Controller } from "stimulus"
import Tagify from "@yaireo/tagify/dist/tagify.esm.js"

export default class extends Controller {

  static targets = [
    "ErrorsList",
    "SpeciesTagifyInput", "SpeciesInput",
    "ZipcodeInput",
    "NameInput",
    "EmailInput",
    "PhoneNumberInput",
    "VarietyInputContainer",
    "VarietyInput",
    "UnloadingOptionsContainer",
    "UnloadingOptionCheckboxLoadingDock",
    "UnloadingOptionCheckboxForklift",
    "UnloadingOptionCheckboxPalletJack",
    "UnloadingOptionCheckboxNone",
  ]

  connect() {
    this.userUnloadingOptionsSelected = ["none"]
    this.tagifySpecies()
  }

  tagifySpecies() {
    const values = eval(this.SpeciesTagifyInputTarget.value)
    const tagify = new Tagify(this.SpeciesTagifyInputTarget, {
      autoComplete: { enabled: true },
      dropdown: {
        enabled: 0,
        mapValueTo: 'searchBy'
      },
      enforceWhitelist: false,
      maxTags: 1,
      whitelist: values
    })
    tagify.removeAllTags()
    tagify.addTags([{ value: "Cereal Rye" }])

    tagify.on('change', (e) => {
      const valueObject = e.detail.tagify.getCleanValue()

      if (valueObject[0] && valueObject[0].value) {
        this.SpeciesInputTarget.value = valueObject[0].value
      } else {
        this.SpeciesInputTarget.value = ""
      }
    })
  }

  onVarietyChange(e) {
    var value = e.target.dataset.value

    if (value !== "VNS") {
      this.VarietyInputTarget.value = ""
      this.VarietyInputContainerTarget.classList.remove("hidden")
    } else {
      this.VarietyInputContainerTarget.classList.add("hidden")
      this.VarietyInputTarget.value = "VNS"
    }
  }

  onUnloadingOptionDockChange(e) {
    if (e.target.checked) {
      this.userUnloadingOptionsSelected.push("dock")
      this.UnloadingOptionCheckboxNoneTarget.checked = false
      this.userUnloadingOptionsSelected = this.userUnloadingOptionsSelected.filter(opt => opt !== "none")
    } else {
      this.userUnloadingOptionsSelected = this.userUnloadingOptionsSelected.filter(opt => opt !== "dock")
      this.setUnloadingOptionNoneWhenEmpty()
    }
  }

  onUnloadingOptionForkliftChange(e) {
    if (e.target.checked) {
      this.userUnloadingOptionsSelected.push("forklift")
      this.UnloadingOptionCheckboxNoneTarget.checked = false
      this.userUnloadingOptionsSelected = this.userUnloadingOptionsSelected.filter(opt => opt !== "none")
    } else {
      this.userUnloadingOptionsSelected = this.userUnloadingOptionsSelected.filter(opt => opt !== "forklift")
      this.setUnloadingOptionNoneWhenEmpty()
    }
  }

  onUnloadingOptionPalletJackChange(e) {
    if (e.target.checked) {
      this.userUnloadingOptionsSelected.push("pallet_jack")
      this.UnloadingOptionCheckboxNoneTarget.checked = false
      this.userUnloadingOptionsSelected = this.userUnloadingOptionsSelected.filter(opt => opt !== "none")
    } else {
      this.userUnloadingOptionsSelected = this.userUnloadingOptionsSelected.filter(opt => opt !== "pallet_jack")
      this.setUnloadingOptionNoneWhenEmpty()
    }
  }

  onUnloadingOptionNoneChange(e) {
    if (e.target.checked) {
      this.userUnloadingOptionsSelected.push("none")
    } else {
      this.userUnloadingOptionsSelected = this.userUnloadingOptionsSelected.filter(opt => opt !== "none")
    }
  }

  setUnloadingOptionNoneWhenEmpty() {
    if (this.UnloadingOptionCheckboxLoadingDockTarget.checked == false && this.UnloadingOptionCheckboxForkliftTarget.checked == false &&  this.UnloadingOptionCheckboxPalletJackTarget.checked == false) {
      this.UnloadingOptionCheckboxNoneTarget.checked = true
      this.userUnloadingOptionsSelected.push("none")
    }
  }

  onSubmit(e) {
    e.stopPropagation()
    e.preventDefault()

    e.target.form.checkValidity()

    if (e.target.form.reportValidity()) {
      e.currentTarget.disabled = true
      e.currentTarget.classList.add("opacity-50")
      e.currentTarget.innerHTML = "Submitting"

      e.target.form.requestSubmit()
    }
  }

}