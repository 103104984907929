import pluralize from 'pluralize'

$(document).ready(function() {
  if ($(".order-optimizer-content").length > 0) {
    // ajax poll for status updates on suggestion lookups
    if ($(".order-optimizer-current-product-lookup").length > 0 && !$(".order-optimizer-progress-done-container").is(':visible') && !$(".order-optimizer-progress-error-container").is(':visible')) {
      var pollingInterval
      pollingInterval = setInterval(function() {
        var shippingOptimizationId = $(".order-optimizer-current-product-lookup").data('id')
        $.ajax({
          method: "GET",
          dataType: 'json',
          cache: false,
          url: "/shipping_optimizations/"+shippingOptimizationId+"/progress",
        }).done((response) => {
          if (response.status == 'pending') {
            $("#order-optimizer-current-product-lookup-title").html(response.current_suggestion_lookup_title)
            $(".order-optimizer-progress-bar").width(response.current_suggestion_lookup_progress_percent * 2)
            $("#order-optimizer-current-product-lookup-progress-text").html(response.current_suggestion_lookup_progress_percent)
          } else if (response.status == 'done') {
            clearInterval(pollingInterval)
            $(".order-optimizer-progress-bar").width(response.current_suggestion_lookup_progress_percent * 2)
            $("#order-optimizer-current-product-lookup-progress-text").html(response.current_suggestion_lookup_progress_percent)
            if (response.number_of_suggestions == 0) {
              var numResultsText = $("#order-optimizer-progress-num-suggestions").closest(".order-optimizer-text")
              $(numResultsText).siblings(".order-optimizer-heading").html("It looks like you've already found the cheapest option!")
              $(numResultsText).hide()
              $(".order-optimizer-button").html("View Shipping Cost")
              $(".order-optimizer-button").attr('href', '/shipping_requests/'+response.shipping_optimization_suggestions[0].selected_shipping_request_id)
            } else {
              $("#order-optimizer-progress-max-savings").html(response.max_savings.toFixed(2))
              var numberOfSuggestionsText = `${response.number_of_suggestions} ${pluralize('recommended change', response.number_of_suggestions)}`
              $("#order-optimizer-progress-num-suggestions").html(numberOfSuggestionsText)
            }
            $(".order-optimizer-progress-done-container").show()
            $(".order-optimizer-progress-pending-container").hide()
          } else if (response.status == 'failed') {
            clearInterval(pollingInterval)
            window.location.reload()
          }
        }).fail((error) => {
          clearInterval(pollingInterval)
          window.location.reload()
        })
      }, 2000)
    }
  }
})
