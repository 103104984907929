import { Controller } from "stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {

  static targets = [
    // subscription related
    "SubscribeModal",
    "SubscribeModalContent",
    "SubscribeSuccessModal",
    "InputSubscriptionEmail",
    "ErrorListSubscriptionServer",
    "ErrorSubscriptionEmail",
    // bid related
    "BidForm",
    "BidSuccessMessage",
    "BidFormContent",
    "BidQuantity",
    "BidVarietySpecies",
    "BidCondition",
    "BidBuyerNotesContainer",
    "BidBuyerNotes",
    "InputBidRequestId",
    "InputBidRate", "InputBidMessage", "InputBidPackagingBulk", "InputBidPackagingTotes", "InputBidPackagingBags", "InputBidExpiresAt", "InputBidAvailableAt", "InputBidFile", "InputBidEmail", "InputBidName", "InputBidPhoneNumber", "InputBidZipcode", "InputBidBusinessTypeBusiness", "InputBidBusinessTypeFarm", "InputBidLoadingOptionDock", "InputBidLoadingOptionForklift", "InputBidLoadingOptionNone",
    "ErrorListBidServer",
    "ErrorBidMessage", "ErrorBidRate", "ErrorBidEmail", "ErrorBidName", "ErrorBidPhoneNumber",  "ErrorBidZipcode",
  ]

  initialize() {
    var bidRequestRows = $(".bid-request-row")
    this.bidRequests = bidRequestRows.map((index, row) => $(row).data("bid-request"))
    this.bidFormData = new FormData
    this.bidBusinessType = $("#bid_business_type_business").val()
    this.bidLoadingOption = $("#bid_loading_option_dock").val()
  }

  toggleBidForm(e) {
    e.preventDefault()
    var bidRequestId = $(e.target).data("bidRequestId")

    if (bidRequestId) {
      var bidRequest = [...this.bidRequests].find((br) => br['id'] === bidRequestId)
      this.InputBidRequestIdTarget.value = bidRequest.id
      this.BidQuantityTarget.innerHTML = bidRequest.quantity
      this.BidVarietySpeciesTarget.innerHTML = `${bidRequest.species} - ${bidRequest.variety}`
      this.BidConditionTarget.innerHTML = `${bidRequest.condition === 'Unclean' ? ' - Uncleaned' : ''}`
      if (bidRequest.notes !== "" && bidRequest.notes !== null) {
        this.BidBuyerNotesTarget.innerHTML = bidRequest.notes
        this.BidBuyerNotesContainerTarget.classList.remove("hidden")
      } else {
        this.BidBuyerNotesContainerTarget.classList.add("hidden")
      }
    }
    this.BidFormTarget.classList.toggle("hidden")
    this.BidSuccessMessageTarget.classList.add("hidden")
    this.BidFormContentTarget.classList.remove("hidden")
  }

  toggleSubscribeModal(e) {
    e.preventDefault()
    this.SubscribeModalTarget.classList.toggle("hidden")
    this.SubscribeSuccessModalTarget.classList.add("hidden")
    this.SubscribeModalContentTarget.classList.remove("hidden")
  }

  async validateAndSubmitSubscription(e) {
    e.preventDefault()
    e.stopPropagation()
    this.ErrorSubscriptionEmailTarget.classList.add("hidden")
    this.InputSubscriptionEmailTarget.classList.remove("border-red-400")

    if (this.InputSubscriptionEmailTarget.value === null || this.InputSubscriptionEmailTarget.value === '') {
      this.ErrorSubscriptionEmailTarget.classList.remove("hidden")
      this.InputSubscriptionEmailTarget.classList.add("border-red-400")
      return
    }

    var form = e.target.form
    const request = new FetchRequest(form.method, form.action, { body: new FormData(form), responseKind: 'json' })
    const response = await request.perform()

    if (response.ok) {
      $(form).trigger("reset")
      this.ErrorListSubscriptionServerTarget.classList.add("hidden")
      this.SubscribeSuccessModalTarget.classList.remove("hidden")
      this.SubscribeModalContentTarget.classList.add("hidden")
      if (window.current_admin_user == null) {
        if (window.gtag) {
          gtag('event', 'SubscribeToRFBs', { event_category: 'Lead', currency: 'USD', value: 150.00 })
        }
        if (window.fbq) {
          window.fbq('track', 'Subscribe', { currency: 'USD', value: 100.00 })
        }
      }
    } else {
      const errorJson = await response.json
      var errorHtml = []

      if (errorJson.errors) {
        $.each(errorJson.errors, (_, error) => {
          errorHtml = errorHtml.concat('<li>' + error + '</li>')
        })
      }

      this.ErrorListSubscriptionServerTarget.classList.remove("hidden")
      setTimeout(() => {
        this.ErrorListSubscriptionServerTarget.innerHTML = errorHtml.join("")
      }, 250)
    }
  }

  async validateAndSubmitBid(e) {
    e.preventDefault()
    this.ErrorListBidServerTarget.classList.add("hidden")
    this.ErrorBidMessageTarget.classList.add("hidden")
    this.InputBidMessageTarget.classList.remove("border-red-400")
    this.ErrorBidEmailTarget.classList.add("hidden")
    this.InputBidEmailTarget.classList.remove("border-red-400")
    this.ErrorBidNameTarget.classList.add("hidden")
    this.InputBidNameTarget.classList.remove("border-red-400")
    this.ErrorBidPhoneNumberTarget.classList.add("hidden")
    this.InputBidPhoneNumberTarget.classList.remove("border-red-400")
    this.ErrorBidZipcodeTarget.classList.add("hidden")
    this.InputBidZipcodeTarget.classList.remove("border-red-400")

    var errorBidMessage = (this.InputBidMessageTarget.value === null || this.InputBidMessageTarget.value === '')
    if (errorBidMessage) {
      this.ErrorBidMessageTarget.classList.remove("hidden")
      this.InputBidMessageTarget.classList.add("border-red-400")
    }
    var errorBidRate = (this.InputBidRateTarget.value === null || this.InputBidRateTarget.value === '')
    if (errorBidRate) {
      this.ErrorBidRateTarget.classList.remove("hidden")
      this.InputBidRateTarget.classList.add("border-red-400")
    }
    var errorBidEmail = (this.InputBidEmailTarget.value === null || this.InputBidEmailTarget.value === '')
    if (errorBidEmail) {
      this.ErrorBidEmailTarget.classList.remove("hidden")
      this.InputBidEmailTarget.classList.add("border-red-400")
    }
    var errorBidName = (this.InputBidNameTarget.value === null || this.InputBidNameTarget.value === '')
    if (errorBidName) {
      this.ErrorBidNameTarget.classList.remove("hidden")
      this.InputBidNameTarget.classList.add("border-red-400")
    }
    var errorBidPhoneNumber = (this.InputBidPhoneNumberTarget.value === null || this.InputBidPhoneNumberTarget.value === '')
    if (errorBidPhoneNumber) {
      this.ErrorBidPhoneNumberTarget.classList.remove("hidden")
      this.InputBidPhoneNumberTarget.classList.add("border-red-400")
    }
    var errorBidZipcode = (this.InputBidZipcodeTarget.value === null || this.InputBidZipcodeTarget.value === '')
    if (errorBidZipcode) {
      this.ErrorBidZipcodeTarget.classList.remove("hidden")
      this.InputBidZipcodeTarget.classList.add("border-red-400")
    }

    if (
      errorBidMessage ||
      errorBidRate ||
      errorBidEmail ||
      errorBidName ||
      errorBidPhoneNumber ||
      errorBidZipcode
    ) {
      return
    }
    var form = e.target.form
    var packagingData = []

    if (this.InputBidPackagingBulkTarget.checked) {
      packagingData.push("Bulk")
    }
    if (this.InputBidPackagingTotesTarget.checked) {
      packagingData.push("Totes")
    }
    if (this.InputBidPackagingBagsTarget.checked) {
      packagingData.push("Bags")
    }

    this.bidFormData.set("bid[bid_request_id]", this.InputBidRequestIdTarget.value)
    this.bidFormData.set("bid[rate]", this.InputBidRateTarget.value)
    this.bidFormData.set("bid[message]", this.InputBidMessageTarget.value)
    packagingData.map((item) => this.bidFormData.append("bid[packaging][]", item))
    this.bidFormData.set("bid[expires_at]", this.InputBidExpiresAtTarget.value)
    this.bidFormData.set("bid[available_at]", this.InputBidAvailableAtTarget.value)
    this.bidFormData.set("bid[email]", this.InputBidEmailTarget.value)
    this.bidFormData.set("bid[name]", this.InputBidNameTarget.value)
    this.bidFormData.set("bid[phone_number]", this.InputBidPhoneNumberTarget.value)
    this.bidFormData.set("bid[zipcode]", this.InputBidZipcodeTarget.value)
    this.bidFormData.set("bid[business_type]", this.bidBusinessType)
    this.bidFormData.set("bid[loading_option]", this.bidLoadingOption)

    var form = e.target.form
    const request = new FetchRequest("POST", form.action, { body: this.bidFormData, responseKind: 'json' })
    const response = await request.perform()
    if (response.ok) {
      $(form).trigger("reset")
      this.ErrorListBidServerTarget.classList.add("hidden")
      this.BidFormContentTarget.classList.add("hidden")
      this.BidSuccessMessageTarget.classList.remove("hidden")
      if (window.current_admin_user == null) {
        if (window.gtag) {
          gtag('event', 'SubmitBid', { event_category: 'Lead', currency: 'USD', value: 100.00 })
        }
        if (window.fbq) {
          window.fbq('track', 'SubmitApplication', { currency: 'USD', value: 100.00 })
        }
      }
    } else {
      const errorJson = await response.json
      console.log('errorJson: ', errorJson)
      var errorHtml = []

      if (errorJson.errors) {
        $.each(errorJson.errors, (index, error) => {
          errorHtml = errorHtml.concat('<li>' + error + '</li>')
        })
      }

      this.ErrorListBidServerTarget.classList.remove("hidden")
      setTimeout(() => {
        this.ErrorListBidServerTarget.innerHTML = errorHtml.join("")
      }, 250)
    }
  }

  onBidBusinessTypeChange(e) {
    this.bidBusinessType = $(`input[name='${e.target.name}']:checked`).val()
  }

  onBidLoadingOptionChange(e) {
    this.bidLoadingOption = $(`input[name='${e.target.name}']:checked`).val()
  }

  onFileChange() {
    var files = this.InputBidFileTarget.files
    this.bidFormData.set('bid[seed_test_image]', files[0])
  }

}
