import { Controller } from "stimulus"
import flatpickr from "flatpickr"
import Tagify from "@yaireo/tagify/dist/tagify.esm.js"

export default class extends Controller {
  static targets = [
    "ErrorsList",
    "SpeciesInput",
    "SpeciesError",
    "VarietyInput",
    "VarietyError",
    "DeliveryDateContainer",
    "QuantityInput",
    "QuantityError",
    "EmailInput",
    "EmailError",
    "NameInput",
    "NameError",
    "PhoneInput",
    "PhoneError",
    "ZipcodeInput",
    "ZipcodeError",
    "CreateSection",
    "BidFormContainer",
    "SuccessContainer",
  ]

  initialize() {
    this.inited = this.inited || null

    if (this.inited === null) {
      this.initFlatPickr()
      this.initSpeciesTagify()
      this.inited = true
    }
  }

  initFlatPickr() {
    flatpickr(".flatpickr", { wrap: true, dateFormat: 'M j, Y' })
  }

  initSpeciesTagify() {
    const speciesElem = $("#bid_request_species_tagify")[0]
    const tagify = new Tagify(speciesElem, {
      autoComplete: { enabled: true },
      dropdown: { enabled: 0 },
      maxTags: 1,
      whitelist: eval(speciesElem.value)
    })

    tagify.on('change', (e) => {
      const valueObject = e.detail.tagify.getCleanValue()
      if (valueObject[0] && valueObject[0].value) {
        this.SpeciesInputTarget.value = valueObject[0].value
      } else {
        this.SpeciesInputTarget.value = ""
      }
    })
  }

  onCreateClick(e) {
    e.preventDefault()
    this.BidFormContainerTarget.classList.remove("hidden")
    this.CreateSectionTarget.classList.add("hidden")
  }

  onCancel(e) {
    e.preventDefault()
    this.BidFormContainerTarget.classList.add("hidden")
    this.CreateSectionTarget.classList.remove("hidden")
  }

  onDeliveryFlexibleChange(e) {
    if (e.target.checked) {
      this.DeliveryDateContainerTarget.classList.add("hidden")
    }
  }

  onDeliverySpecificChange(e) {
    if (e.target.checked) {
      this.DeliveryDateContainerTarget.classList.remove("hidden")
    }
  }

  validateFormFields() {
    this.SpeciesErrorTarget.classList.add("hidden")
    this.VarietyErrorTarget.classList.add("hidden")
    this.VarietyInputTarget.classList.remove("border-red-400")
    this.QuantityErrorTarget.classList.add("hidden")
    this.QuantityInputTarget.classList.remove("border-red-400")
    this.EmailErrorTarget.classList.add("hidden")
    $(this.EmailErrorTarget).html("can't be blank")
    this.EmailInputTarget.classList.remove("border-red-400")
    this.NameErrorTarget.classList.add("hidden")
    this.NameInputTarget.classList.remove("border-red-400")
    this.PhoneErrorTarget.classList.add("hidden")
    this.PhoneInputTarget.classList.remove("border-red-400")
    this.ZipcodeErrorTarget.classList.add("hidden")
    this.ZipcodeInputTarget.classList.remove("border-red-400")

    // reverse order so scroll to element works right
    this.errorUnloadingOption = (this.dataUnloadingOption === null || this.dataUnloadingOption === '')
    this.errorBusinessType = (this.dataBusinessType === null || this.dataBusinessType === '')
    this.errorZipcode = (this.dataZipcode === null || this.dataZipcode === '')

    if (this.ZipcodeInputTarget.value === null || this.ZipcodeInputTarget.value === '') {
      this.ZipcodeErrorTarget.classList.remove("hidden")
      this.ZipcodeInputTarget.classList.add("border-red-400")
      this.ZipcodeErrorTarget.scrollIntoView(false)
    }

    if (this.PhoneInputTarget.value === null || this.PhoneInputTarget.value === '') {
      this.PhoneErrorTarget.classList.remove("hidden")
      this.PhoneInputTarget.classList.add("border-red-400")
      this.PhoneErrorTarget.scrollIntoView(false)
    }

    if (this.NameInputTarget.value === null || this.NameInputTarget.value === '') {
      this.NameErrorTarget.classList.remove("hidden")
      this.NameInputTarget.classList.add("border-red-400")
      this.NameErrorTarget.scrollIntoView(false)
    }

    if (this.EmailInputTarget.value === null || this.EmailInputTarget.value === '') {
      this.EmailErrorTarget.classList.remove("hidden")
      this.EmailInputTarget.classList.add("border-red-400")
      this.EmailErrorTarget.scrollIntoView(false)
    }

    if (this.QuantityInputTarget.value === null || this.QuantityInputTarget.value === '') {
      this.QuantityErrorTarget.classList.remove("hidden")
      this.QuantityInputTarget.classList.add("border-red-400")
      this.QuantityErrorTarget.scrollIntoView(false)
    }

    if (this.VarietyInputTarget.value === null || this.VarietyInputTarget.value === '') {
      this.VarietyErrorTarget.classList.remove("hidden")
      this.VarietyInputTarget.classList.add("border-red-400")
      this.VarietyErrorTarget.scrollIntoView(false)
    }

    if (this.SpeciesInputTarget.value === null || this.SpeciesInputTarget.value === '') {
      this.SpeciesErrorTarget.classList.remove("hidden")
      this.SpeciesErrorTarget.scrollIntoView(false)
    }
  }

  validateAndSubmit(e) {
    e.preventDefault()

    this.validateFormFields()
    var form = e.target.form

    $.ajax({
      method: "POST",
      dataType: 'json',
      url: form.action,
      data: $(form).serialize()
    }).done((response) => {
      $(form).trigger("reset")
      this.BidFormContainerTarget.classList.add("hidden")
      this.SuccessContainerTarget.classList.remove("hidden")
      this.ErrorsListTarget.classList.add("hidden")
      if (window.current_admin_user == null) {
        if (window.gtag) {
          gtag('event', 'RequestForBid', { event_category: 'Lead', currency: 'USD', value: 100.00 })
        }
        if (window.fbq) {
          window.fbq('track', 'AddToWishlist', { currency: 'USD', value: 100.00 })
        }
      }
    }).fail((error) => {
      var errorJson = error.responseJSON
      var errorHtml = []

      if (errorJson.errors) {
        $.each(errorJson.errors, (index, error) => {
          errorHtml = errorHtml.concat('<li>' + error + '</li>')

          if (error.indexOf("email") >= 0) {
            this.EmailInputTarget.classList.add("border-red-400")
            $(this.EmailErrorTarget).html(error)
            this.EmailErrorTarget.classList.remove("hidden")
          }
        })
      }

      $(this.ErrorsListTarget).html(errorHtml)
      this.ErrorsListTarget.classList.remove("hidden")
    })
  }
}
