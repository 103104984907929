$(document).ready(function() {
  // GA & FB event
  if ($("#shipping-results-container").length > 0) {
    if (window.current_admin_user == null) {
      if (window.gtag) {
        gtag('event', 'RequestedShipping', { event_category: 'Lead', currency: 'USD', value: 15.00 })
      }
      if (window.fbq) {
        fbq('track', 'InitiateCheckout', { currency: 'USD', value: 15.00 })
      }
    }
  }
  // when submitting email request for shipping
  if ($("#email-submission-heading").length > 0) {
    if (window.current_admin_user == null) {
      if (window.gtag) {
        gtag('event', 'RequestedShipping', { event_category: 'Lead', currency: 'USD', value: 10.00 })
      }
      if (window.fbq) {
        window.fbq('track', 'InitiateCheckout', { currency: 'USD', value: 10.00 })
      }
    }
  }

  // form submit button
  $("#calc-shipping-submit").on('touchstart click', (e) => {
    e.preventDefault()

    $(e.target).attr("disabled", "disabled")
  })

  var updateOrderSummaryForShipping = (rate) => {
    var subTotal = $("#checkout-order-summary-subtotal").text()
    subTotal = subTotal.replace("$", "").replace(",", "")
    subTotal = parseFloat(subTotal)
    var discountAmount = $("#checkout-order-summary-discount").text().replace("$","").replace(",","") || 0.0
    discountAmount = parseFloat(discountAmount)
    var palletFeeAmount = $("#checkout-order-summary-pallet-fee").text().replace("$","").replace(",","") || 0.0
    palletFeeAmount = parseFloat(palletFeeAmount)
    var total = Math.round((subTotal + rate + discountAmount + palletFeeAmount) * 100) / 100
    $("#checkout-order-summary-shipping-rate").html(`$${rate.toFixed(2)}`)
    $(".checkout-order-summary-total-price").html(`$${total.toFixed(2).toLocaleString()}`)
  }

  var updateRatePerUnitForShipping = (rate) => {
    var subTotal = $("#checkout-order-summary-subtotal").text()
    subTotal = subTotal.replace("$", "").replace(",", "")
    subTotal = parseFloat(subTotal)
    var discountAmount = $("#checkout-order-summary-discount").text().replace("$","").replace(",","") || 0.0
    discountAmount = parseFloat(discountAmount)
    var palletFeeAmount = $("#checkout-order-summary-pallet-fee").text().replace("$","").replace(",","") || 0.0
    palletFeeAmount = parseFloat(palletFeeAmount)
    var ratePerUnitElement = $("#rate-per-unit")
    var weight = $(ratePerUnitElement).data('num-units')
    var ratePerUnit = (rate + subTotal + discountAmount + palletFeeAmount) / weight
    ratePerUnitElement.html(`$${ratePerUnit.toFixed(2).toLocaleString()}`)
  }

  var pollForStatusUpdate = (count) => {
    var attemptCount = count + 1

    $.ajax({
      method: "GET",
      dataType: 'json',
      url: window.location.href,
      data: { from_js: true }
    }).done((response) => {
      if (response.status == 'pending') {
        if (attemptCount > 24) {
          handleShippingLookupError('Did not receive a result from the server before 60 seconds')
        } else {
          setTimeout(() => pollForStatusUpdate(attemptCount), 5000)
        }
      } else if (response.status == 'done') {
        if (window.current_admin_user == null) {
          if (window.gtag) {
            gtag('event', 'RequestedShipping', { event_category: 'Lead', currency: 'USD', value: 10.00 })
          }
          if (window.fbq) {
            fbq('track', 'InitiateCheckout', { currency: 'USD', value: 10.00 })
          }
        }

        window.location.reload()
      } else if (response.status == 'failed') {
        $("#shipping-result-loading").hide()
        $("#shipping-lookup-failed").addClass('-show')
      }
    }).fail((error) => {
      handleShippingLookupError(error)
    })
  }

  var handleShippingLookupError = (error) => {
    console.log('error: ', error)
    $("#shipping-result-loading").hide()
    $("#shipping-lookup-failed").addClass('-show')
    if (window.Sentry) {
      Sentry.captureMessage('Error on shipping lookup: ' + JSON.stringify(error))
    }
  }

  $(document).ready(() => {
    var shippingRequestAttemptCount = 0

    if ($("#shipping-results-container").length > 0) {
      if ($("#shipping-results-container").data('status') == 'pending') {
        setTimeout(() => pollForStatusUpdate(shippingRequestAttemptCount), 5000)
      }
    }
  })

  $("input[type=radio][name='api_models_shipping_request[shipping_selection_index]']").change((e) => {
    var radioRate = $("#shipping-result-rate-" + e.target.value).text()
    radioRate = radioRate.replace("$", "").replace(",", "")
    radioRate = parseFloat(radioRate)
    updateOrderSummaryForShipping(radioRate)
    updateRatePerUnitForShipping(radioRate)
  })

  // disable/enable continue to billing button
  $("#shipping-to-billing-button").on('touchstart click', (e) => {
    if ($("#shipping-to-billing-button").hasClass('-disabled')) {
      e.preventDefault()
    }
  })
})
