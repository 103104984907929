import { Controller } from "stimulus"
import {enter, leave} from 'el-transition';

export default class extends Controller {
  static targets = ["Menu", "Container", "LogoImage", "LoggedInBanner"]

  connect() {
    this.element[this.identifier] = this

    this.loggedInAdmin = Array.from(this.element.classList).indexOf("top-0") < 0

    this.io = new IntersectionObserver(this.observerCallback.bind(this), {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    })
    const mainContent = document.querySelector('#navbar-scroll-target')
    this.io.observe(mainContent)
  }

  toggleMenu() {
    const mainContainer = document.querySelector("#main-container")
    const isDesktop = window.matchMedia("only screen and (min-width: 1024px)").matches
    const shouldAddPadding = window.scrollY <= this.MenuTarget.offsetHeight && !isDesktop

    if (this.MenuTarget.classList.contains('hidden')) {
      enter(this.MenuTarget)
      if (shouldAddPadding) {
        mainContainer.style.paddingTop = `${this.MenuTarget.offsetHeight}px`
      }
    } else {
      leave(this.MenuTarget)
      mainContainer.style.paddingTop = `0px`
    }
  }

  observerCallback(entries, observer) {
    const entry = entries[0]
    const { isIntersecting } = entry

    if (this.loggedInAdmin) {
      if (!isIntersecting) {
        this.element.classList.add("!top-0")
      } else {
        this.element.classList.remove("!top-0")
      }
    }
  }

}
